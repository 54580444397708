// import News from './components/News';
import {
  Navigate, Route, Routes
} from "react-router-dom";
import PageTopButton from './components/common/PageTopButton';
import Cookie from './components/cookie/Cookie';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import Index from './pages';
import AboutRoute from './pages/about/AboutRoute';
import FAQ from './pages/faq/FAQ';
import Info from './pages/footer/Info';
import NewsRoute from './pages/news/NewsRoute';
import PriceRoute from './pages/price/PriceRoute';
import QueryRoute from './pages/query/QueryRoute';
import SiteMap from './pages/sitemap/SiteMap';
import SitePolicy from './pages/sitepolicy/SitePolicy';
import Download from './pages/support/Download';
import ICReader from "./pages/support/ICReader";
import Setup from "./pages/support/Setup";
import SupportRoute from './pages/support/SupportRoute';
import Tool from "./pages/support/Tool";
import Themes from './pages/themes/Themes';
import WebProb from './pages/webprob/WebProb';
import ScrollTop from './components/common/scrollTop';
import { useLocation } from "react-router-dom";

function App() {
  // 現在のURLを取得するためにフックを使用
  const location = useLocation();
  const path = location.pathname;

  // 旧URLからのリダイレクト: お問い合わせ
  if (path === "/contact.php") {
    window.location.href = "/contact/";

  // 旧URLからのリダイレクト: ダウンロード(PSAサービス)
  } else if (path === "/footer/info.html" && location.hash === "#f-1-03") {
    window.location.href = window.location.origin + "/footer/info#s03"
  }

  return (
    <>
      <Header />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Index/>} />
        <Route path="/about/*" element={<AboutRoute/>}/>
        <Route path="/query/*" element={<QueryRoute/>}/>
        <Route path="/price/*" element={<PriceRoute/>}/>
        <Route path="/support/*" element={<SupportRoute/>}/>
        <Route path="/soft-t2/installer/ddriver" element={<Download/>}/>
        <Route path="/footer/info" element={<Info/>}/>
        <Route path="/news/*" element={<NewsRoute/>}/>
        <Route path="/faq" element={<FAQ/>}/>
        <Route path="/web-prob" element={<WebProb/>}/>
        <Route path="/sitepolicy" element={<SitePolicy/>}/>
        <Route path="/themes" element={<Themes/>}/>
        <Route path="/sitemap" element={<SiteMap/>}/>
        <Route path="/setup" element={<Setup />} />
        <Route path="/icreader" element={<ICReader />} />
        <Route path="/tool" element={<Tool />} />
        <Route path="/themes" element={<Themes />} />

        {/* 旧URLからのリダイレクト: よくあるご質問 */}
        <Route path="/support/faq/index.html" element={<Navigate to="/faq" />} />
        <Route path="/support/faq/faq_20_132.html" element={<Navigate to="/faq/#faq2_2929" />} />
        <Route path="/support/faq/faq_21_230.html" element={<Navigate to="/faq/#faq2_3004" />} />
        <Route path="/support/faq/faq_21_209.html" element={<Navigate to="/faq/#faq2_2992" />} />
        {/* 旧URLからのリダイレクト: 公開情報 */}
        <Route path="/footer/info.html" element={<Navigate to="/footer/info" />} />
        <Route path="/ps2" element={<Navigate to="/footer/info" />} />

        {/* 旧URLからのリダイレクト: サイトポリシー */}
        <Route path="/footer/sitepolicy.html" element={<Navigate to="/sitepolicy" />} />
        {/* 旧URLからのリダイレクト: サイトマップ */}
        <Route path="/footer/sitemap.html" element={<Navigate to="/sitemap" />} />
        {/* 旧URLからのリダイレクト: お問い合わせ */}
        <Route path="/contact.php" element={<Navigate to="/contact/" />} />
        {/* 旧URLからのリダイレクト: 申し込み書作成ツール */}
        <Route path="/web-prob/" element={<Navigate to="/web-prob" />} />
        {/* https://www.e-probatio.com/authn/ からのリダイレクト */}
        <Route path="/authn" element={<Navigate to="/footer/info/#s05" />} />
        {/* Not Found */}
        <Route path="*" element={
          // バックエンドで実装されたお問い合わせページの場合以外の無効なURLはTOPページへリダイレクトする
          path !== "/contact" && !path.includes("files") ? <Navigate to="/" /> : <></>
        } />

      </Routes>
      <Cookie />
      <Footer />
      <PageTopButton />
    </>
  );
}

export default App;
