import { useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import EyeCatch from "../../components/common/EyeCatch";
import Body from "../../components/layout/Body";
import useHashLink from "../../hooks/useHashLink";
import { Link } from "react-router-dom";

const Info = () => {
    useHashLink();
    const routes = [
      { path: "/", breadcrumb: "e-Probatio トップ" },
      { path: "/footer/info", breadcrumb: "公開情報" },
    ];
    useEffect(() => {
        document.title = "公開情報 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "電子入札などでは、電子証明書が必須となります。ご利用いただくには、ICカードリーダーも必要になります。パソコンへのソフトウェアのインストールおよびICカードの機能検証などを行うセットアップサービス（有料）もご用意しております。"
            );
            // CSSが干渉するため、該当CSSのJSでhref属性をクリアしている
            document.getElementById("price")?.setAttribute("href", "")
    }, [])
    return (
        <>
            <Body bodyClass="price">
                <EyeCatch title="公開情報" />
                <div className="page-content">
                    <br></br>
                    <div className="p_anchor2">
                        <ul className="faq__anchor__list">
                            <li>
                                <a href="#s01" className="e_btn_anchor">
                                    ダウンロード(PS2)
                                </a>
                            </li>
                            <li>
                                <a href="#s02" className="e_btn_anchor">
                                    証明書開示(PS2)
                                </a>
                            </li>
                            <li>
                                <a href="#s03" className="e_btn_anchor">
                                    ダウンロード(PSA)
                                </a>
                            </li>
                            <li>
                                <a href="#s04" className="e_btn_anchor">
                                    証明書開示(PSA)
                                </a>
                            </li>
                           
                        </ul>
                    </div>
                    <div className="p_anchor2">
                    <ul className="faq__anchor__list">
                    <li>
                                <a href="#s05" className="e_btn_anchor">
                                    ダウンロード(AuthN)
                                </a>
                            </li>
                            <li>
                                <a href="#s06" className="e_btn_anchor">
                                    証明書開示(AuthN)
                                </a>
                            </li>
                            
               
                            <li>
                                <a href="https://poa.e-probatio.com/public/index.html" className="e_btn_anchor" target="_blank" rel="noreferrer">公開情報(PoA)</a>
                            </li>
                            <li>
                                
                            </li>
                    </ul>
                     </div>
                     <br></br>
                    <div id="s01" className="price__block price01">
                        <div className="inner">
                            <h3 className="price__cont__ttl ">
                                ダウンロード(PS2サービス)
                            </h3>
                            <div className="rule">
                                <h4 className="price__cont__title">
                                    各種規程類(PS2サービス)
                                </h4>
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-kiyaku.pdf"
                                                className="p_btn type03"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                ご利用約款 （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/cps.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                e-Probatio認証局
                                                <br />
                                                認証業務規程（CPS） （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-cp.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                e-Probatio PS2サービス
                                                <br />
                                                証明書ポリシ（CP） （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-import.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                重要事項説明書 （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/personal.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                個人情報取扱要領 （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-signature.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                署名検証者同意書 （PDF）
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="application">
                                <h3 className="price__cont__title">
                                    お申し込み書類一式(PS2サービス)
                                </h3>
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/query/first/#step02"
                                                className="p_btn type03"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                電子証明書利用申込書 （PDF）
                                            </a>
                                        </p>
                                        <p className="text">
                                            上記ボタンをクリック後に表示されるページの画面中央にある水色タブの「印刷して手書きで利用申込書を作成する」をクリックしてください。<br/>
                                            「ローマ字変換表」は<a href="/download/ps2-hebon.pdf" className="blank white" target="_blank" rel="noreferrer" style={{color: "#fff"}}>こちら</a>をご参照ください。
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-iccard.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                ICカードリーダー購入申込書<br/> （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-register.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                企業在籍証明書 （PDF）
                                            </a>
                                        </p>
                                        <p className="text">
                                            ICカードに委任項目の登録を希望される場合は、「企業在籍兼電子委任項目証明書」をご利用ください。
                                            作成は<a href="/web-prob/" className="blank white" target="_blank" rel="noreferrer" style={{color: "#fff"}}>こちら</a>から、
                                            詳しくは<Link to={{ pathname: "/faq/", hash: "#faq1_2988"}} className="blank white" target="_blank" rel="noreferrer" style={{color: "#fff"}}>こちら</Link>をご参照ください。
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-publicregister.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                省官庁/地方公共団体
                                                在籍証明書 （PDF）
                                            </a>
                                        </p>
                                        <p className="text">
                                            ICカードに委任項目の登録を希望される場合は、「省官庁/地方公共団体在籍兼電子委任項目証明書」をご利用ください。
                                            作成は<a href="/web-prob/" className="blank white" target="_blank" rel="noreferrer" style={{color: "#fff"}}>こちら</a>から、
                                            詳しくは<Link to={{ pathname: "/faq/", hash: "#faq1_2988"}} className="blank white" target="_blank" rel="noreferrer" style={{color: "#fff"}}>こちら</Link>をご参照ください。
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-revoke.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                電子証明書失効申込書兼
                                                届出書 （PDF）
                                            </a>
                                        </p>
                                        <p className="text">
                                            【ご注意】
                                            <br />
                                            失効申込書兼届出書が弊社に到着次第、直ちに失効処理を行います。
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-signature.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                署名検証者同意書 （PDF）
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="information">
                                <h3 className="price__cont__title">情報開示</h3>
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/ps2-infoopen.pdf"
                                                className="p_btn type03"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                個人情報開示申込書 （PDF）
                                            </a>
                                        </p>
                                        <p className="text">
                                            お客さまのご要望により、お客さまの証明書内容の情報を開示する場合の料金は、3,300円(税込)/件です。
                                            こちらの個人情報開示申込書に記載し、お申し込みください。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="s02" className="price__block price02">
                        <div className="inner">
                            <h3 className="price__cont__ttl">
                                証明書開示(PS2サービス)
                            </h3>
                            <p>
                                認証局が自身を証明するために発行する証明書（ＤＥＲ形式）を開示いたします。
                            </p>
                            <ul>
                                <li>
                                    証明書を利用する場合は、必ずフィンガープリントと照らし合わせてからご利用ください。
                                </li>
                                <li>
                                    ダウンロードするには、それぞれのリンクをクリックしてください。
                                </li>
                            </ul>
                            <div className="rule">
                            <div className="table_title">
                                    <h3 className="price__cont__title">
                                    第５世代（Ｇ５）自己署名証明書
                                    </h3>
                                    <span className="price__cont__sub">
                                    <a href="/download/e-ProbatioPS2_G5.cer">e-ProbatioPS2_G5.cer &gt;</a>
                                    </span>
                                </div>
                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0134 b1d0 0000 0002</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                                C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                                2023年06月08日 14:17:03 ～ 2033年06月07日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            f20d b1ec b99a 946e 81fb 9eb3 b53e c8eb a621 1cab d8fe 7b1a 8aa2 19f8 a158 cef4
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                                5c1c f060 6c0b 4bac 9dce d9c5 899b 0220 8952 8adc
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                    第４世代（Ｇ４）自己署名証明書
                                    </h3>
                                    <span className="price__cont__sub">
                                    <a href="/download/e-ProbatioPS2_G4.cer">e-ProbatioPS2_G4.cer &gt;</a>
                                    </span>
                                </div>
                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0133 f079 0000 0002</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                                C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                                2018年11月13日 14:28:07 ～ 2028年11月12日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            a26e 2546 1c8c 7607 917a 643d a959 daf1 7bc7 f2ca 38a9 01c3 68ed 2c94 ca15 9755
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                                6143 2f16 6791 92a8 2d25 e13f 0b16 bc66 41c3 c735
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                        第３世代（Ｇ３）自己署名証明書
                                    </h3>
                                    <span className="price__cont__sub">
                                        <a href="/download/e-ProbatioPS2_G3.cer">e-ProbatioPS2_G3.cer &gt;</a>
                                    </span>
                                </div>
                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0133 53d3 0000 0002</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                            2014年02月13日 13:41:35 ～ 2024年02月12日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            4663 651B BAD3 AB10 0159 3978 96E2 D3EF 214C EC46 BD2E 17CF 7B3D 196F F75E 8FE0
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            d711 a86b e2dc c29f f9dd bb89 8cdd 87de 4b59 9010
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                        第２世代（Ｇ２）自己署名証明書
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G2.cer"><span>e-ProbatioPS2_G2.cer &gt;</span></a>
                                </div>
                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0132 18f6 0001 026f</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                            2010年05月11日 14:26:59 ～ 2020年05月10日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            2EFD EFF6 1830 5CAC 1D42 5A3D BEC1 7E67 835D 7A11 FB8B F767 9106 A939 CEB2 286D
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            1c33 7f20 3321 3742 8ca7 b053 3d9d c58f 1fa1 4cd7
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                        第１世代（Ｇ１）自己署名証明書
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G1.cer"><span>e-ProbatioPS2_G1.cer &gt;</span></a>
                                </div>

                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0131 f389 0000 0001</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                            2005年08月25日 14:16:05 ～ 2015年08月24日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            C079 0C12 735D CD2C 07BD 5A22 371D FFBF B34D 4512 D86E 0DD2 5DCD 4669 6B98 85B5
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            6b8b 8eaf aa54 624c 2cbd 4e6c 6def e6a6 50f9 bc1f
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                        リンク証明書（Ｇ５ｗｉｔｈＧ４）
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G5withG4.cer"><span>e-ProbatioPS2_G5withG4.cer &gt;</span></a>
                                </div>
                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0134 b1d0 0000 0001</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                                2023年06月08日 14:17:03 ～
                                                2028年11月12日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            5706 264e 0132 88f0 4115 e256 7f7a 4c5f 0616 2257 5880 e9fe 3138 f92a 3bbb 9703
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            25c8 07e8 e5ed 0552 7f26 5c19 42b3 9631 970a df78
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                    リンク証明書（Ｇ４ｗｉｔｈＧ５）
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G4withG5.cer"><span>e-ProbatioPS2_G4withG5.cer &gt;</span></a>
                                </div>

                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0134 b1d0 0000 0003</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                            2018年11月13日 14:28:07 ～ 2028年11月12日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            972b 33e9 6fb1 a350 7e06 0633 40a6 fe57 656e bd13 cce3 28ae 3e0c e0c9 25e0 310d
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            b067 9d5b 47f3 a03a 35e8 0d1d 2217 8631 e51a 8f31
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                        リンク証明書（Ｇ４ｗｉｔｈＧ３）
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G4withG3.cer"><span>e-ProbatioPS2_G4withG3.cer &gt;</span></a>
                                </div>
                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0133 f079 0000 0001</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                                2018年11月13日 14:28:07 ～
                                                2024年02月12日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            5554 32b0 52d2 e6dc 64fa c36e a070 0336 d4af f979 7f43 0a2e 51b2 01e2 d970 c07d
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            f38a 9c23 a8c9 d57c e25e 0f48 1c87 07c0 9656 4fd9
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                    リンク証明書（Ｇ３ｗｉｔｈＧ４）
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G3withG4.cer"><span>e-ProbatioPS2_G3withG4.cer &gt;</span></a>
                                </div>

                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0133 f079 0000 0003</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                            2014年02月13日 13:41:35 ～ 2024年02月12日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            f147 6c6a 43b2 0a3c a43e 80fc b5bc de32 1101 8a04 cc93 432d f059 7ad5 1b19 7a5c
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            cecb 7645 ea4f 9899 642c f59e d16c 7672 c5de 065b
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                    リンク証明書（Ｇ３ｗｉｔｈＧ２）
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G3withG2.cer"><span>e-ProbatioPS2_G3withG2.cer &gt;</span></a>
                                </div>

                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0133 53d3 0000 0001</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                            2014年02月13日 13:41:35 ～ 2020年05月10日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            EAB4 CA03 987E 7191 6FF2 8ABC AA47 F0F8 E018 77EB DFA9 E123 6818 AC10 2B4D 0791
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            30ae 8ff9 8988 27d3 821f 41ca e7f2 1552 6683 b125
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                    リンク証明書（Ｇ２ｗｉｔｈＧ３）
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G2withG3.cer"><span>e-ProbatioPS2_G2withG3.cer &gt;</span></a>
                                </div>

                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0133 53d3 0000 0003</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                            2010年05月11日 14:26:59 ～ 2020年05月10日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            03F6 4FFB B47D 88CE A427 F3A5 807F 20F9 3CD3 6897 B9C4 B829 6B90 6E5B 6B53 E33C0
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            ea17 1c83 4851 5558 cdb8 b6a9 7a2e e6d0 5b2a c19e
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="table_title">
                                    <h3 className="price__cont__title">
                                    リンク証明書（Ｇ２ｗｉｔｈＧ１）
                                    </h3>
                                    <a href="/download/e-ProbatioPS2_G2withG1.cer"><span>e-ProbatioPS2_G2withG1.cer &gt;</span></a>
                                </div>

                                <table className="default tbl_1">
                                    <tbody>
                                        <tr>
                                            <th className="blue">
                                                シリアル番号
                                                <br className="sp" />
                                                (Serial Number)
                                            </th>
                                            <td>0132 18f6 0001 026e</td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                所有識別子
                                                <br className="sp" />
                                                (Subject DN)
                                            </th>
                                            <td>
                                            C=JP,O=e-Probatio CA,OU=e-Probatio PS2
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                有効期限
                                                <br className="sp" />
                                                (Validity)
                                            </th>
                                            <td>
                                            2010年05月11日 14:26:59 ～ 2015年08月24日 23:59:59
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-256)
                                            </th>
                                            <td>
                                            9C5C 8A96 0610 F17C 4C68 8943 6A1B 60C0 BDE5 2A71 16D1 2C5C 8BF2 4BA0 F942 90DC
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="blue">
                                                証明書フィンガープリント
                                                <br />
                                                (SHA-1)
                                            </th>
                                            <td>
                                            0e92 a9c7 7529 7adf cab3 456c 0aa0 6745 231f 3717
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div id="s03" className="price__block price03">
                        <div className="inner">
                            <h3 className="price__cont__ttl">
                                ダウンロード(PSAサービス)
                            </h3>
                            <div className="rule">
                                <h3 className="price__cont__title">
                                    各種規程類(PSAサービス)
                                </h3>
                                <p className="title">【共通】</p>
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/cps.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                e-Probatio認証局
                                                <br />
                                                認証業務規程（CPS） （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/psa-cp.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                e-Probatio PSAサービス
                                                <br />
                                                証明書ポリシ（CP） （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/psa-signature.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                署名検証者同意書 （PDF）
                                            </a>
                                        </p>
                                    </div>
                                </div>

                                <p className="title">【税理士用証明書】</p>
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/psa-nzr-kiyaku.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                加入者利用規定 （PDF）
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="rule">
                                <h3 className="price__cont__title">
                                    お申し込み書類一式(PSAサービス)
                                </h3>
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="http://www.nichizeiren.or.jp/taxaccount/auth/"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                税理士用証明書について
                                            </a>
                                        </p>
                                        <p className="text">
                                            日本税理士会連合会のページへ移動します。<br/>
                                            「ローマ字変換表」は<a href="/download/psa-hebon.pdf" className="blank white" target="_blank" rel="noreferrer" style={{color: "#fff"}}>こちら</a>をご参照ください。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="s04" className="price__block price02">
                    <div className="inner">
                        <h3 className="price__cont__ttl">
                            証明書開示(PSAサービス)
                        </h3>
                        <p>
                            認証局が自身を証明するために発行する証明書（ＤＥＲ形式）を開示いたします。
                        </p>
                        <ul>
                            <li>
                                証明書を利用する場合は、必ずフィンガープリントと照らし合わせてからご利用ください。
                            </li>
                            <li>
                                ダウンロードするには、それぞれのリンクをクリックしてください。
                            </li>
                        </ul>
                        <div className="rule">
                            <div className="table_title">
                                <h3 className="price__cont__title">
                                    第２世代（Ｇ２）自己署名証明書
                                </h3>
                                <a href="/download/e-ProbatioPSA_G2.cer"><span>e-ProbatioPSA_G2.cer &gt;</span></a>
                            </div>

                            <table className="default tbl_1">
                                <tbody>
                                    <tr>
                                        <th className="blue">
                                            シリアル番号
                                            <br className="sp" />
                                            (Serial Number)
                                        </th>
                                        <td>0134 3f02 0000 0002</td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            所有識別子
                                            <br className="sp" />
                                            (Subject DN)
                                        </th>
                                        <td>
                                        C=JP,O=e-Probatio CA,OU=e-Probatio PSA
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            有効期限
                                            <br className="sp" />
                                            (Validity)
                                        </th>
                                        <td>
                                        2020年12月18日 11:18:57 ～ 2030年12月17日 23:59:59
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-256)
                                        </th>
                                        <td>
                                        b3af 76d2 dc45 a3fb 7ce5 6745 2bc8 361d 7410 497d 5ab2 899b 37a0 d65d c153 3dd4
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-1)
                                        </th>
                                        <td>
                                        3d16 8556 c50e 3ab9 91ec 53d2 ffed ef2e bad5 50ba
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="table_title">
                                <h3 className="price__cont__title">
                                    第１世代（Ｇ１）自己署名証明書
                                </h3>
                                <a href="/download/e-ProbatioPSA_G1.cer"><span>e-ProbatioPSA_G1.cer &gt;</span></a>
                            </div>

                            <table className="default tbl_1">
                                <tbody>
                                    <tr>
                                        <th className="blue">
                                            シリアル番号
                                            <br className="sp" />
                                            (Serial Number)
                                        </th>
                                        <td>0133 c575 0000 0001</td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            所有識別子
                                            <br className="sp" />
                                            (Subject DN)
                                        </th>
                                        <td>
                                        C=JP,O=e-Probatio CA,OU=e-Probatio PSA
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            有効期限
                                            <br className="sp" />
                                            (Validity)
                                        </th>
                                        <td>
                                        2016年9月2日 11:29:12 ～ 2026年9月1日 23:59:59
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-256)
                                        </th>
                                        <td>
                                        9850 1923 55fb 4dbd de68 4c34 ee2b 98f2 475b 3d7b 3844 920a c07a b2e9 c066 de8a
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-1)
                                        </th>
                                        <td>
                                        bfab 64a5 5fb3 e31b 1855 f0fc 2ebd 52fb 6a49 f24c
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="table_title">
                                <h3 className="price__cont__title">
                                リンク証明書（Ｇ２ｗｉｔｈＧ１）
                                </h3>
                                <a href="/download/e-ProbatioPSA_G2withG1.cer"><span>e-ProbatioPSA_G2withG1.cer &gt;</span></a>
                            </div>

                            <table className="default tbl_1">
                                <tbody>
                                    <tr>
                                        <th className="blue">
                                            シリアル番号
                                            <br className="sp" />
                                            (Serial Number)
                                        </th>
                                        <td>0134 3f02 0000 0001</td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            所有識別子
                                            <br className="sp" />
                                            (Subject DN)
                                        </th>
                                        <td>
                                        C=JP,O=e-Probatio CA,OU=e-Probatio PSA
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            有効期限
                                            <br className="sp" />
                                            (Validity)
                                        </th>
                                        <td>
                                        2020年12月18日 11:18:57 ～ 2026年9月1日 23:59:59
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-256)
                                        </th>
                                        <td>
                                        0617 629b de12 d9e4 6c58 6288 8316 15bd 432a 6322 72ad 3cf8 dc06 e745 a3f5 47c7
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-1)
                                        </th>
                                        <td>
                                        41d1 bcfc 4725 62b7 50d8 41e5 b226 4f48 20d0 260a
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="table_title">
                                <h3 className="price__cont__title">
                                リンク証明書（Ｇ１ｗｉｔｈＧ２）
                                </h3>
                                <a href="/download/e-ProbatioPSA_G1withG2.cer"><span>e-ProbatioPSA_G1withG2.cer &gt;</span></a>
                            </div>

                            <table className="default tbl_1">
                                <tbody>
                                    <tr>
                                        <th className="blue">
                                            シリアル番号
                                            <br className="sp" />
                                            (Serial Number)
                                        </th>
                                        <td>0134 3f02 0000 0003</td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            所有識別子
                                            <br className="sp" />
                                            (Subject DN)
                                        </th>
                                        <td>
                                        C=JP,O=e-Probatio CA,OU=e-Probatio PSA
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            有効期限
                                            <br className="sp" />
                                            (Validity)
                                        </th>
                                        <td>
                                        2016年9月2日 11:29:12 ～ 2026年9月1日 23:59:59
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-256)
                                        </th>
                                        <td>
                                        64b4 a56a 740e 5570 856c 3e1e f41c e3c8 4f86 eb86 d57c e7e7 eb1d 7c41 8dc5 09f4
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-1)
                                        </th>
                                        <td>
                                        5ac7 745d e6fb 1c07 ebc4 c907 b927 15e0 75cd 3ea0
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id="s05" className="price__block price03">
                        <div className="inner">
                            <h3 className="price__cont__ttl">
                                ダウンロード（AuthNサービス）
                            </h3>
                            <div className="rule">
                                <h3 className="price__cont__title">
                                    各種規程類（AuthNサービス）
                                </h3>
                                <p className="title">【共通】</p>
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/cps.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                e-Probatio認証局
                                                <br />
                                                認証業務規程（CPS） （PDF）
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flexItem">
                                        <p className="linkBtn">
                                            <a
                                                href="/download/authn-cp.pdf"
                                                className="p_btn type04"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                e-Probatio AuthNサービス
                                                <br />
                                                証明書ポリシ（CP） （PDF）
                                            </a>
                                        </p>
                                    </div>
                                 </div>
                            </div>

                         </div>
                </div>
                <div id="s06" className="price__block price02">
                    <div className="inner">
                        <h3 className="price__cont__ttl">
                            証明書開示（AuthNサービス）
                        </h3>
                        <p>
                            認証局が自身を証明するために発行する証明書（ＤＥＲ形式）を開示いたします。
                        </p>
                        <ul>
                            <li>
                                証明書を利用する場合は、必ずフィンガープリントと照らし合わせてからご利用ください。
                            </li>
                            <li>
                                ダウンロードするには、それぞれのリンクをクリックしてください。
                            </li>
                        </ul>
                        <div className="rule">
                            <div className="table_title">
                                <h3 className="price__cont__title">
                                    第１世代（Ｇ１）自己署名証明書
                                </h3>
                                <a href="/download/e-ProbatioAuthN_G1.cer"><span>e-ProbatioAuthN_G1.cer &gt;</span></a>
                            </div>

                            <table className="default tbl_1">
                                <tbody>
                                    <tr>
                                        <th className="blue">
                                            シリアル番号
                                            <br className="sp" />
                                            (Serial Number)
                                        </th>
                                        <td>0134 fed3 0000 0007</td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            所有識別子
                                            <br className="sp" />
                                            (Subject DN)
                                        </th>
                                        <td>
                                        C=JP,O=e-Probatio CA,OU=e-Probatio AuthN
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            有効期限
                                            <br className="sp" />
                                            (Validity)
                                        </th>
                                        <td>
                                        2025年3月23日 15:38:01 ～ 2036年3月22日 23:59:59
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-256)
                                        </th>
                                        <td>
                                        59de a52d b859 bdf8 e7a8 ee38 2d09 bb17 71c8 c859 ca36 b229 228f 3480 ad2e 4674
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="blue">
                                            証明書フィンガープリント
                                            <br />
                                            (SHA-1)
                                        </th>
                                        <td>
                                        39d2 3a76 0ad7 720d 5732 8031 211a 3667 7304 9a91
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            

                          
                           

                    
 
                            
                        </div>
                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Info;